import { Controller } from "stimulus";
import tomSelect from 'tom-select';

export default class FormController extends Controller {
  connect() {
    this.initPlaylistSelect();
  }

  initPlaylistSelect() {
    if (!this.hasPlaylistSelectTarget) return;

    this.playlistTom = new tomSelect(this.playlistSelectTarget, {
      create: (input) => {
        return {
          value: "new:" + input,
          text: input
        }
      }
    });
  }

  removeTimestamp(e) {
    e.preventDefault();
    e.target.closest("[data-timestamp]").remove();
  }
}

FormController.targets = ["playlistSelect"]

