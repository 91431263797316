/* eslint-disable no-undef */
// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

import Appsignal from 'utils/appsignal';
import { installErrorHandler } from "@appsignal/stimulus"

const application = Application.start()
window.Stimlulus = application;
// Report errors to appsignal
installErrorHandler(Appsignal, application);

const context = require.context("controllers", true, /\.js$/);
const contextComponents = require.context("../../components", true, /_controller\.js$/);
const viewControllers = require.context("../../views", true, /_controller\.js$/);
const subscriberV2Components = require.context("../../../engines/subscriber_v2/app/components/subscriber_v2", true, /_controller\.js$/)
const subscriberV2Views = require.context("../../../engines/subscriber_v2/app/views/subscriber_v2", true, /_controller\.js$/);





application.load(
  definitionsFromContext(context).concat(
    definitionsFromContext(contextComponents)
  ).concat(
    definitionsFromContext(viewControllers)
  ).concat(
    definitionsFromContext(subscriberV2Components)
  ).concat(
    definitionsFromContext(subscriberV2Views)
  )
);