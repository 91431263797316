import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["question"];

  connect() {
    this.questions = []
  }

  addOrRemoveQuestion(event) {
    const id = event.target.dataset.id
    if (this.questions.includes(id)) {
      this.questions = this.questions.filter(question => question !== id)
    } else {
      this.questions.push(id)
    }
    this.handleNextButton()
  }

  handleNextButton() {
    const next = document.querySelector("[data-id='next']")
    if (!next) return;

    if (this.questions.length > 0) {
      next.classList.remove("pointer-events-none")
      next.classList.remove("!text-slate-400")
      next.addEventListener("click", this.persistQuestions.bind(this))
    } else {
      next.classList.add("pointer-events-none")
      next.classList.add("!text-slate-400")
      next.removeEventListener("click", this.persistQuestions.bind(this))
    }
  }

  persistQuestions(event) {
    event.preventDefault()
    const form = document.querySelector("#questions_form")
    this.questions.forEach(question => {
      form.insertAdjacentHTML("beforeend", `<input type="hidden" name="question_ids[]" value="${question}" />`)
    })
    form.submit()
  }
}
